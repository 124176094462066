import React, {useState} from 'react';
import {isEmpty} from "../assets/Utils";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";
import {useDispatch, useSelector} from "react-redux";
import {deletePictureGuidedTour, setPictureGuidedTour} from "../actions/pictureGuidedTour.action";

const AdminNewMagazine = ({ closePopup }) => {
    const contentNewMagazine = useSelector(
        (state) => state.pictureGuidedTourReducer
    );
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch();




    const handleIsFormValid = () => {
        const form = document.getElementById("form-new-partner");
        if (form.checkValidity()) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handlePictureUpload = async (e) => {
        const picture = e.target.files[0];
        let formData = new FormData();
        formData.append("file", picture);
        dispatch(await deletePictureGuidedTour(contentNewMagazine));
        dispatch(await setPictureGuidedTour(formData));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e.target.value);
    }
    return (
        <div className="popup popup-new-magazine">
            <div className="popup-content-new-magazine-container">
                <div className="popup-magazine-header">
                    <h2>Créer un magazine</h2>
                    <div className="cancel-button" onClick={closePopup}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <section className="magazine-infos">
                            <div className="label-info-magazine-container">
                                <label htmlFor="name-guided-tour">Nom de du magazine</label>
                                <input
                                    type="text"
                                    id="name-magazine"
                                    name="name-magazine"
                                    required
                                />
                            </div>
                        </section>
                        <section className="magazine-image">
                            <h3>Contenu</h3>
                            <div className="new-magazine-content-container">
                                <label htmlFor="picture-content">Ajouter une photo</label>
                                <input
                                    type="file"
                                    id="picture-content"
                                    onChange={handlePictureUpload}
                                    hidden
                                    multiple
                                />
                            </div>
                        </section>
                        {!isEmpty(contentNewMagazine) && (
                            <AdminSortingPartnerUploads />
                        )}
                        <input
                            id={isFormValid ? "valid" : "invalid"}
                            type="submit"
                            value="Créer le magazine"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminNewMagazine;