const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return (
      value.length === 0 ||
      value[0] === "" ||
      value[0] === null ||
      value[0] === undefined
    );
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return value === undefined || value === null || value === "";
};

export const normalizeString = (string) => {
  return string
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(" ", "-")
      .replaceAll("'", "");
};

export const generatesubDomain = (name, data) => {
  let subDomain;
  if (!isEmpty(name)) {
    const partSubDomContent = normalizeString(name);
    let subDomainAvailable = 0;
    data.forEach((item) => {
      if (item.subDomain === partSubDomContent) {
        subDomainAvailable += 1;
      }
    });
    if (subDomainAvailable > 0) {
      subDomain = partSubDomContent + subDomainAvailable.toString();
    } else {
      subDomain = partSubDomContent;
    }
  } else {
    subDomain = "";
  }
  return subDomain;
};

export { isEmpty };
