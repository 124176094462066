import React, { useEffect, useState } from "react";
import { isEmpty } from "../assets/Utils";
import { useSelector } from "react-redux";
import CityListEntry from "../components/CityListEntry";
import BackButton from "../components/BackButton";
import LogoLbp from "../components/LogoLbp";
import CountrySelect from "../components/CountrySelect";

const CitiesList = () => {
  const dataCity = useSelector((state) => state.cityReducer);
  const [currentLocation, setCurrentLocation] = useState({});
  const [sortedCities, setSortedCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchInput, setSearchInput] = useState("");


  const collectDataLocation = (pos) => {
    const crd = pos.coords;
    const data = {
      latitude: crd.latitude,
      longitude: crd.longitude,
    };
    setCurrentLocation(data);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(collectDataLocation);
  }, [dataCity]);

  const distanceCalculation = async (city) => {
    const coords = await city.coordinates;
    const deltaLat = coords[0] - currentLocation.latitude;
    const deltaLng = coords[1] - currentLocation.longitude;
    return Math.sqrt(deltaLat * deltaLat + deltaLng * deltaLng);
  };

  const sortCities = async (dataCity) => {
    const distances = await Promise.all(
        dataCity.map((city) => distanceCalculation(city))
    );

    return dataCity.sort(
        (a, b) => distances[dataCity.indexOf(a)] - distances[dataCity.indexOf(b)]
    );
  };

  useEffect(() => {
    if (Array.isArray(dataCity) && !isEmpty(dataCity) && !isEmpty(currentLocation)) {
      sortCities(dataCity).then(setSortedCities);
    }
  }, [dataCity, currentLocation]);


  const handleFormChange = (e) => {
    e.preventDefault();
    const searchValue = e.target.value.toLowerCase();
    setSearchInput(searchValue);
    if (Array.isArray(dataCity)) {
      const filteredCities = dataCity.filter((city) => {
        const matchesName = city.name.toLowerCase().includes(searchValue);
        const matchesCountry = selectedCountry ? city.country === selectedCountry.value : true;
        return matchesName && matchesCountry;
      });
      setSortedCities(filteredCities);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const searchValue = searchInput.toLowerCase();
    if (Array.isArray(dataCity)) {
      const filteredCities = dataCity.filter((city) => {
        const matchesName = city.name.toLowerCase().includes(searchValue);
        const matchesCountry = selectedOption ? city.country === selectedOption.value : true;
        return matchesName && matchesCountry && city.isActive;
      });
      setSortedCities(filteredCities);
    }
  };

  return (
      <div className="cities-list-container">
        <section className="header-container">
          <BackButton navPath="ma-ville" stringBtn="Retour à ma ville" />
          <LogoLbp />
        </section>
        <section className="city-selector">
          <div className="city-searchbar-container">
            <h1>Choisissez votre ville</h1>
            <form>
              <CountrySelect
                  onChange={handleCountryChange}
                  value={selectedCountry}
              />
              <input
                  type="text"
                  placeholder="Entrez le nom d'une ville que vous souhaitez chercher..."
                  onChange={(e) => handleFormChange(e)}
              />
            </form>
          </div>
          <div className="city-display">
            {!isEmpty(sortedCities) &&
                sortedCities
                    .filter((city) => city.isActive === true)
                    .map((city) => (
                        <CityListEntry
                            key={city.id}
                            city={city}
                            currentLocation={currentLocation}
                        />
                    ))}
          </div>
        </section>
      </div>
  );
};

export default CitiesList;