import axios from "axios";

export const GET_MAGAZINE = "GET_MAGAZINE";
export const SET_MAGAZINE = "SET_MAGAZINE";
export const UPDATE_MAGAZINE = "UPDATE_MAGAZINE";
export const DELETE_MAGAZINE = "DELETE_MAGAZINE";

export const getMagazine = () => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro" + "/magazines")
            .then((res) => {
                dispatch({ type: GET_MAGAZINE, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setMagazine = (magazine) => {
    return async (dispatch) => {
        return axios
            .post("https://api.lesbonsplans.pro" + "/magazines", magazine)
            .then((res) => {
                dispatch({ type: SET_MAGAZINE, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const updateMagazine = (magazine) => {
    return async (dispatch) => {
        return axios
            .put(
                "https://api.lesbonsplans.pro" + "/magazines/" + magazine._id,
                magazine
            )
            .then((res) => {
                dispatch({
                    type: UPDATE_MAGAZINE,
                    payload: { ...res.data, ...magazine },
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deleteMagazine = (magazineId) => {
    return async (dispatch) => {
        return axios
            .delete("https://api.lesbonsplans.pro" + "/magazines/" + magazineId)
            .then((res) => {
                dispatch({ type: DELETE_MAGAZINE, payload: magazineId });
            })
            .catch((err) => console.log(err));
    };
}
