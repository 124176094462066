import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import PartnerListEntry from "../components/PartnerListEntry";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { useParams } from "react-router";
import { calculateDistance } from "../assets/utils/distance";

const PartnersList = () => {
    const { citysubDomain, categorysubDomain } = useParams();
    const dataPartner = useSelector((state) => state.partnerReducer);
    const [currentCity, setCurrentCity] = useState({});
    const [currentCategory, setcurrentCategory] = useState({});
    const [filteredPartner, setFilteredPartner] = useState([]);
    const [currentLocation, setCurrentLocation] = useState({});
    const [sortedPartner, setSortedPartner] = useState([]);

    // Collect location data
    const collectDataLocation = (pos) => {
        const crd = pos.coords;
        const data = {
            latitude: crd.latitude,
            longitude: crd.longitude,
        };
        setCurrentLocation(data);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(collectDataLocation);
    }, []);

    // Fetch city data
    useEffect(() => {
        const fetchCity = async () => {
            try {
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${citysubDomain}`);
                const data = await response.json();
                setCurrentCity(data);
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        fetchCity();
    }, [citysubDomain]);

    // Fetch category data
    useEffect(() => {
        const fetchCategory = async () => {
            if (!isEmpty(currentCity)) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/category/search?subDomain=${categorysubDomain}`);
                    const data = await response.json();
                    setcurrentCategory(data);
                } catch (error) {
                    console.error("Error fetching category data:", error);
                }
            }
        };

        fetchCategory();
    }, [categorysubDomain, currentCity]);

    // Fetch partners
    useEffect(() => {
        const fetchPartner = async () => {
            if (!isEmpty(currentCity) && !isEmpty(currentCategory) && currentCategory.name) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/partner/searchByCityAndCategory?cityId=${currentCity._id}&categoryName=${currentCategory.name}`);
                    const data = await response.json();
                    setFilteredPartner(data.filter(part => part.isActive));
                } catch (error) {
                    console.error('Error fetching partners:', error);
                }
            }
        };

        fetchPartner();
    }, [currentCity, currentCategory]);

    // Sort partners by distance
    useEffect(() => {
        if (!isEmpty(filteredPartner) && !isEmpty(currentLocation)) {
            const partnersWithDistance = filteredPartner.map((partner) => {
                const distance = calculateDistance(
                    currentLocation.latitude,
                    currentLocation.longitude,
                    partner.coordinates[0],
                    partner.coordinates[1]
                );
                return { ...partner, distance };
            });

            setSortedPartner(partnersWithDistance.sort((a, b) => a.distance - b.distance));
        }
    }, [filteredPartner, currentLocation]);

    const cityBackground = {
        background: !isEmpty(currentCity) && currentCity.picture
            ? `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${currentCity.picture}) fixed center bottom/cover`
            : 'none',
    };

    return (
        <div className="partners-list-container" style={cityBackground}>
            <Header navPath="categories" stringBtn="Retourner aux catégories"/>
            <h1>{currentCategory.name}</h1>
            <section className="partners-list">
                {!isEmpty(sortedPartner) &&
                    sortedPartner.map((part) => (
                        <PartnerListEntry key={part._id} partner={part} distance={part.distance.toFixed(2)} />
                    ))
                }
            </section>
        </div>
    );
};

export default PartnersList;