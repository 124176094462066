import {useSelector} from "react-redux";
import Header from "../layouts/Header";
import React from "react";

const GuidePrestige = () => {
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const currentCity = useSelector((state) => state.cityReducer);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    return (
        <div className="news-city-container" style={cityBackground}>
            <Header/>
            <section className="iframe-container">
                <iframe
                    title="actualites"
                    src={contentCurrentCity.guidePrestigeLink}
                    frameBorder="0"
                ></iframe>
            </section>
        </div>
    );
}
export default GuidePrestige;

