import axios from "axios";
import { deletePictureGuidedTour } from "./pictureGuidedTour.action";
import { deleteAudioGuidedTour } from "./contentGuidedTour.action";

export const GET_GUIDED_TOUR = "GET_GUIDED_TOUR";
export const GET_GUIDED_TOUR_BY_ID = "GET_GUIDED_TOUR_BY_ID";
export const SET_GUIDED_TOUR = "SET_GUIDED_TOUR";
export const UPDATE_GUIDED_TOUR = "UPDATE_GUIDED_TOUR";
export const DELETE_GUIDED_TOUR = "DELETE_GUIDED_TOUR";


export const getGuidedTour = () => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro" + "/guidedTour")
            .then((res) => {
                dispatch({ type: GET_GUIDED_TOUR, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};
export const getGuidedTourById = (guidedTourId) => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro" + "/guidedTour/" + guidedTourId)
            .then((res) => {
                dispatch({ type: GET_GUIDED_TOUR_BY_ID, payload: res.data });
                return res.data;
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };
};

export const setGuidedTour = (guidedTour) => {
    return async (dispatch) => {
        return axios
            .post("https://api.lesbonsplans.pro" + "/guidedTour", guidedTour)
            .then((res) => {
                dispatch({ type: SET_GUIDED_TOUR, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const updateGuidedTour = (guidedTour) => {
    return async (dispatch) => {
        return axios
            .put(
                "https://api.lesbonsplans.pro" + "/guidedTour/" + guidedTour._id,
                guidedTour
            )
            .then((res) => {
                dispatch({
                    type: UPDATE_GUIDED_TOUR,
                    payload: { ...res.data, ...guidedTour },
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deleteGuidedTour = (guidedTour) => {
    return async (dispatch) => {
        try {
            dispatch(deletePictureGuidedTour(guidedTour.picture));
            console.log(guidedTour.contentAudio);
            Object.keys(guidedTour.contentAudio).forEach((audioId) => {
                dispatch(deleteAudioGuidedTour(guidedTour.contentAudio[audioId]));
            });
            await axios.delete("https://api.lesbonsplans.pro" + "/guidedTour/" + guidedTour._id);
            dispatch({ type: DELETE_GUIDED_TOUR, payload: guidedTour._id });
        } catch (err) {
            console.log(err);
        }
    };
};