import React, {useState} from "react";
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import AdminNewCity from "../layouts/AdminNewCity";
import PopupCitySocialMedia from "../layouts/PopupCitySocialMedia";
import AdminUpdateCity from "../layouts/AdminUpdateCity";
import {updateCity, deleteCity, getCity} from "../actions/city.action";
import DeleteConfirmationModal from "../layouts/AdminPopupSuppression";

const AdminCities = () => {
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const dataPartner = useSelector((state) => state.partnerReducer);
    const dataCity = useSelector((state) => state.cityReducer);
    const [popupCitySocialMedia, setPopupCitySocialMedia] = useState({});
    const [popupCityUpdate, setPopupCityUpdate] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [cityToDelete, setCityToDelete] = useState(null);

    const dispatch = useDispatch();

    const handleActivateCity = (e, city) => {
        const updatedCity = {...city, isActive: !city.isActive};
        dispatch(updateCity(updatedCity));
    };

    const partnerInTheCity = (city) => {
        try {
            return dataPartner
                .filter((partner) => partner.city === city._id)
                .map((partner) => partner);
        } catch (error) {
            console.error("Erreur lors de la récupération des partenaires :", error);
            return [];
        }
    };

    const handleDeleteClick = async (city) => {
        setCityToDelete(city);
        setShowDeleteModal(true);

        const row = document.querySelector(`tr[data-city-id="${city._id}"]`);
        if (row) {
            row.classList.add('deleted');
        }
    };

    const getContentCityById = (contentCityId) => {
        if (Array.isArray(dataContentCity)) {
            return dataContentCity.find((contentCity) => contentCity._id === contentCityId);
        } else {
            console.error("dataContentCity is not an array");
            return null;
        }
    };



    const handleConfirmDelete = () => {
        const partner = partnerInTheCity(cityToDelete);
        const contentCity = getContentCityById(cityToDelete.contentCity);
        dispatch(deleteCity(cityToDelete, partner, contentCity));
        setShowDeleteModal(false);
        setCityToDelete(null);

        const row = document.querySelector(`tr[data-city-id="${cityToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
    };

    const handleRefreshCity = () => {
        dispatch(getCity());
        window.alert(
            "Tableau des villes actualisé.\nToutes les villes sont à présent modifiables et activables."
        );
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);

        const row = document.querySelector(`tr[data-city-id="${cityToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
        setCityToDelete(null);
    };

    return (
        <div className="city-admin-container">
            <HeaderAdmin/>
            <section className="admin-content-container">
                <NavbarAdmin/>
                <div className="admin-content">
                    <AdminNewCity/>
                    <section className="city-management">
                        <h2>Gestion des villes</h2>
                        <button className="btn-refresh" onClick={handleRefreshCity}>
                            Actualiser <i className="bx bx-refresh"></i>
                        </button>
                        <table className="city-table">
                            <thead>
                            <tr>
                                <th>Pays</th>
                                <th>Nom</th>
                                <th>Code Postal</th>
                                <th>Acronyme</th>
                                <th>Image d'arrière plan</th>
                                <th>Logo</th>
                                <th>Réseaux sociaux</th>
                                <th>Actif</th>
                                <th>Activation</th>
                                <th>Modifications</th>
                                <th className="contain-btn">Suppression</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!isEmpty(dataCity) &&
                                dataCity.map((city) => (
                                    <tr key={city.acronym} data-city-id={city._id}>
                                        <td>{city.country}</td>
                                        <td>{city.name}</td>
                                        <td>{city.postalCode}</td>
                                        <td>{city.acronym}</td>
                                        <td>
                                            <img
                                                className="city-background-image"
                                                src={`${"https://api.lesbonsplans.pro"}/upload/city/image/${city.picture}`}
                                                alt={"Image d'arrière plan " + city.name}
                                            />
                                        </td>
                                        <td>
                                            <img
                                                className="city-logo"
                                                src={`${"https://api.lesbonsplans.pro"}/upload/city/image/${city.logo}`}
                                                alt={"Logo " + city.name}
                                            />
                                        </td>
                                        <td className="contain-btn">
                                            {city._id ? (
                                                <button onClick={() => setPopupCitySocialMedia(city)}>
                                                    Réseaux sociaux
                                                </button>
                                            ) : (
                                                <button disabled>Actualisez pour voir</button>
                                            )}
                                        </td>
                                        <td>{city.isActive ? "Oui" : "Non"}</td>

                                        <td className="contain-btn">
                                            {city._id ? (
                                                <button onClick={(e) => handleActivateCity(e, city)}>
                                                    {city.isActive ? "Désactiver" : "Activer"}
                                                </button>
                                            ) : (
                                                <button disabled>Actualisez pour Activer/Désactiver</button>
                                            )}
                                        </td>
                                        <td className="contain-btn">
                                            {city._id ? (
                                                <button onClick={() => setPopupCityUpdate(city)}>
                                                    Modifier
                                                </button>
                                            ) : (
                                                <button disabled>Actualisez pour Modifier</button>
                                            )}
                                        </td>
                                        <td className="contain-btn">
                                            {city._id ? (
                                                <button onClick={() => handleDeleteClick(city)}>
                                                    Supprimer
                                                </button>
                                            ) : (
                                                <button disabled>Actualisez pour Supprimer</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </div>
            </section>
            {!isEmpty(popupCitySocialMedia) && (
                <PopupCitySocialMedia
                    city={popupCitySocialMedia}
                    closePopup={setPopupCitySocialMedia}
                />
            )}
            {!isEmpty(popupCityUpdate) && (
                <AdminUpdateCity
                    city={popupCityUpdate}
                    closePopup={setPopupCityUpdate}
                />
            )}
            <DeleteConfirmationModal
                show={showDeleteModal}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                who="cette ville"
            />
        </div>
    );
};

export default AdminCities;
